import { FC } from "react";
import * as _ from "lodash";

import { Form, Input, AutoComplete } from "@ctra/components";
import { useTranslation, Subscriptions as Content } from "@ctra/i18n";
import { isProduction } from "@ctra/utils";

import { GACategories } from "../../analytics";
import { accountDetailsFormKey, countries } from "../const";
import { FormObserver } from "../FormObserver";
import styles from "./AccountDetailsForm.module.less";

export const AccountDetailsForm: FC = () => {
  const { t } = useTranslation();

  const {
    gettingStarted: {
      accountDetails: {
        contactDetails: {
          form: { labels }
        }
      }
    }
  } = Content;

  return (
    <section className={styles.Wrapper}>
      <FormObserver keyField={accountDetailsFormKey} />
      <Form layout="vertical">
        <Form.Item required name="firstName" label={t<string>(labels.firstName)} hasFeedback={false}>
          <Input
            data-gtm-category={GACategories.gettingStarted}
            data-gtm-action="First name"
            required
            name="firstName"
          />
        </Form.Item>
        <Form.Item required name="lastName" label={t<string>(labels.lastName)} hasFeedback={false}>
          <Input
            data-gtm-category={GACategories.gettingStarted}
            data-gtm-action="Last name"
            required
            name="lastName"
          />
        </Form.Item>
        <Form.Item
          data-gtm-category={GACategories.gettingStarted}
          data-gtm-action="Email"
          required
          name="email"
          label={t<string>(labels.email)}
          hasFeedback={false}
        >
          <Input required disabled type="email" name="email" />
        </Form.Item>
        <Form.Item required name="phoneNumber" label={t<string>(labels.phone)} hasFeedback={false}>
          <Input
            data-gtm-category={GACategories.gettingStarted}
            data-gtm-action="Phone number"
            name="phoneNumber"
          />
        </Form.Item>
        <Form.Item required name="country" label={t<string>(labels.country)} hasFeedback={false}>
          <AutoComplete
            data-gtm-category={GACategories.gettingStarted}
            data-gtm-action="Country"
            filterOption={(inputValue, option) =>
              option && _.isString(option.value)
                ? option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                : false
            }
            options={_.map(isProduction() ? [...countries, "Russia"] : countries, (country) => ({
              label: country,
              value: country
            }))}
            name="country"
          />
        </Form.Item>
      </Form>
    </section>
  );
};
