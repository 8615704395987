import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import * as _ from "lodash";

import { Auth as AuthUI, Spin, Row, Col, Result } from "@ctra/components";
import { isDispatched, isFulfilled, isRejected, parseQuery } from "@ctra/utils";
import { Auth, Subscriptions, SubscriptionsAppState, Session } from "@ctra/api";
import { Subscriptions as Content, useTranslation } from "@ctra/i18n";

import { Routes } from "@routes";
import { LanguageSelector } from "@base";

import styles from "./ActivateUserPage.module.less";

const { AuthPage, WidgetWrapper } = AuthUI;

/**
 * Activate user page
 * @return {JSX.Element}
 * @constructor
 */
const ActivateUserPage: FC = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const dispatch = useDispatch();

  const {
    activation: {
      content: {
        activating: {
          failed: { title, description },
          inProgress
        }
      }
    }
  } = Content;

  const params = new URLSearchParams(search);
  const { token } = parseQuery(params, "token");

  /**
   * Tell if the activatee request has been dispatched
   * @type {boolean}
   */
  const dispatched = useSelector<SubscriptionsAppState, boolean>((state) =>
    isDispatched(state, Auth.types.ACTIVATE_USER)
  );

  /**
   * Tell if the user has been activated
   * @type {boolean}
   */
  const activated = useSelector<SubscriptionsAppState, boolean>((state) =>
    isFulfilled(state, Auth.types.ACTIVATE_USER)
  );

  /**
   * Tell if the activation request has failed, and for what reason
   */
  const [failed, payload] = useSelector<
    SubscriptionsAppState,
    [boolean, { error: string; statusCode: number }]
  >((state) => isRejected(state, Auth.types.ACTIVATE_USER, { withPayload: true }));

  /**
   * Tell if the user is logged in
   * @type {boolean}
   */
  const isLoggedIn = useSelector<SubscriptionsAppState, boolean>(Subscriptions.entities.isLoggedIn);

  /**
   * Log existing user out if already logged in
   */
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(Session.actions.logout());
    }
  }, [isLoggedIn, dispatch]);

  /**
   * Activate user if not already activated
   */
  useEffect(() => {
    if (token && !dispatched && !isLoggedIn) {
      dispatch(Auth.actions.activateUser.start(token, true));
    }
    // eslint-disable-next-line
  }, [dispatched, token, isLoggedIn]);

  return !token || activated ? (
    <Redirect to={{ pathname: Routes.auth.login, search, hash: "welcome" }} />
  ) : (
    <AuthPage className={styles.Wrapper} header={<LanguageSelector />}>
      <WidgetWrapper introTitle="">
        <Row align="middle" justify="center">
          {failed ? (
            <Result
              status="error"
              title={t<string>(title)}
              subTitle={t<string>(description, { originalError: _.defaultTo(payload.error, null) })}
            />
          ) : (
            <Col>
              <Spin tip={t<string>(inProgress)} />
            </Col>
          )}
        </Row>
      </WidgetWrapper>
    </AuthPage>
  );
};

export default ActivateUserPage;
