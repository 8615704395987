import * as Yup from "yup";

import { UserSignupFormValues } from "@ctra/api";
import { useTranslation, Subscriptions as Content } from "@ctra/i18n";

/**
 * Password format requirements
 */
export const passwordFormatRx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/;

const {
  signup: {
    form: {
      validation: {
        emailFormat,
        emailRequired,
        passwordRequired,
        passwordLength,
        passwordsMatch,
        passwordFormat,
        firstNameRequired,
        lastNameRequired
      }
    }
  }
} = Content;

type ValidationSchema = Yup.SchemaOf<Omit<UserSignupFormValues, "signup">>;

/**
 * Validation schema for the sign up form
 */
export const useValidationSchema = (): (() => ValidationSchema) => {
  const { t } = useTranslation();

  /**
   * Validation schema for Sign up form
   */
  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t<string>(emailFormat)).defined(t<string>(emailRequired)).nullable(),
    firstName: Yup.string().defined(t<string>(firstNameRequired)),
    lastName: Yup.string().defined(t<string>(lastNameRequired)),
    password: Yup.string()
      .nullable()
      .defined(t<string>(passwordRequired))
      .min(6, t<string>(passwordLength))
      .matches(passwordFormatRx, t<string>(passwordFormat)),
    confirmPassword: Yup.string()
      .nullable()
      .defined(t<string>(passwordRequired))
      .oneOf([Yup.ref("password"), null], t<string>(passwordsMatch))
  });

  // @ts-ignore - username is a string, but Yup can parse a , separated string as array
  return () => validationSchema;
};
