import { FC, useState } from "react";
import { Formik } from "formik";
import * as _ from "lodash";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";

import { CtraLayout, Typography, Col, Button, Row, LeftOutlined, Skeleton } from "@ctra/components";
import { useTranslation, Subscriptions as Content } from "@ctra/i18n";
import { SignupState, Subscriptions, SubscriptionsAppState, FarmDetailsRequestPayload } from "@ctra/api";
import { isProduction, useDidMount } from "@ctra/utils";

import { Routes } from "@routes";
import { useGettingStarted } from "@getting-started";

import { farmDetailsFormKey, dhmsFormKey, countries } from "../const";
import { FarmDetailsForm } from "../FarmDetailsForm";
import { useGoogleAnalytics } from "@ctra/analytics";
import { GACategories } from "../../analytics";

const { Title, Text } = Typography;
const { WidgetWrapper, ContentWrapper } = CtraLayout;

export type FarmDetailsFormValues = Partial<FarmDetailsRequestPayload>;

/**
 * Farm details page
 * @return {JSX.Element}
 * @constructor
 */
export const FarmDetailsPage: FC = () => {
  const { t } = useTranslation();
  const [mayRedirect, setMayRedirect] = useState<boolean>(false);
  const [isMounting] = useDidMount();

  const {
    meta: {
      farmDetails: { fetch, update }
    },
    api: {
      farmDetails: { update: updateFarmDetails }
    }
  } = useGettingStarted();

  const {
    gettingStarted: {
      next,
      prev,
      farmDetails: { title, description, form }
    }
  } = Content;

  /**
   * Signup state
   * @type {SignupState}
   */
  const signupState = useSelector<SubscriptionsAppState, SignupState>(Subscriptions.entities.getSignupValues);

  /**
   * Test if the form is saved
   * @type {boolean}
   */
  const saved = mayRedirect && !isMounting && !update.pending;

  return saved ? (
    <Redirect to={Routes.app.gettingStarted.selectRole.owner.dhms} />
  ) : (
    <WidgetWrapper
      title={
        <section>
          <Title level={5}>{t<string>(title)}</Title>
          <Text>{t<string>(description)}</Text>
        </section>
      }
    >
      <Skeleton loading={!fetch.dispatched || fetch.pending}>
        <Formik<FarmDetailsFormValues>
          initialValues={_.merge(
            {
              name: "",
              address: "",
              city: "",
              postCode: "",
              country: ""
            },
            _.get(signupState, [farmDetailsFormKey], {})
          )}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(t<string>(form.required)),
            address: Yup.string().required(t<string>(form.required)),
            city: Yup.string().required(t<string>(form.required)),
            postCode: Yup.string().required(t<string>(form.required)),
            country: isProduction()
              ? Yup.string()
                  .oneOf([...countries, "Russia"], t<string>(form.unknownCountry))
                  .nullable()
                  .required(t<string>(form.required))
              : Yup.string()
                  .test(
                    "is-russia",
                    "Did you mean: Russian Federation",
                    (country) => _.lowerCase(country) !== "russia"
                  )
                  .oneOf([...countries, "Russian Federation"], t<string>(form.unknownCountry))
                  .nullable()
                  .required(t<string>(form.required))
          })}
          onSubmit={(values, { setSubmitting }) => {
            updateFarmDetails(_.pick(signupState, [dhmsFormKey, farmDetailsFormKey]));
            setMayRedirect(true);
            setSubmitting(false);
          }}
        >
          {({ submitForm, isValid }) => (
            <>
              <ContentWrapper title={t<string>(form.title)} style={{ marginBottom: "16px" }}>
                <FarmDetailsForm />
              </ContentWrapper>
              <Row gutter={[16, 0]}>
                <Col>
                  <Button
                    data-gtm-category={GACategories.gettingStarted}
                    data-gtm-action="Go to 'DHMS details'"
                    icon={<LeftOutlined />}
                    onClick={() => Subscriptions.history.push(Routes.app.gettingStarted.selectRole.index)}
                  >
                    {t<string>(prev)}
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    data-gtm-category={GACategories.gettingStarted}
                    data-gtm-action="Submit farm details"
                    onClick={submitForm}
                  >
                    {t<string>(next)}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Formik>
      </Skeleton>
    </WidgetWrapper>
  );
};
