import { FC, useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { useDispatch } from "react-redux";
import useDeepCompareEffect from "use-deep-compare-effect";
import * as _ from "lodash";

import { Signup, SignupState } from "@ctra/api";

/**
 * Debounce hook
 * @param {T} value
 * @param {number} delay
 * @return {T}
 */
export function useDebounce<T>(value: T, delay = 500): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useDeepCompareEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

/**
 * Form observer component
 * @param {string | undefined} keyField
 * @return {null}
 * @constructor
 */
export const FormObserver: FC<{ keyField?: string }> = ({ keyField }) => {
  const { values, touched } = useFormikContext<SignupState>();
  const debouncedValues = useDebounce<SignupState>(values, 500);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!_.isEmpty(touched)) {
      dispatch(Signup.actions.commit(keyField ? { [keyField]: debouncedValues } : debouncedValues));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValues, keyField, touched]);

  return null;
};
