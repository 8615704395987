import * as Sentry from "@sentry/react";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const packageJson = require("../../package.json");

/**
 * Init Sentry
 */
const initialise = () =>
  Sentry.init({
    dsn: "https://a019eb9999be54587261dff9d531e9dd@o1047074.ingest.sentry.io/4505900651053056",
    environment: process.env.REACT_APP_BUILD_ENV,
    debug: process.env.REACT_APP_SERVER_ENV === "local",
    maxBreadcrumbs: 30,
    normalizeDepth: 4,
    autoSessionTracking: true,
    sendClientReports: false,
    release:
      process.env.REACT_APP_DEPLOY_BRANCH === "master"
        ? packageJson.version
        : process.env.REACT_APP_DEPLOY_BRANCH,
    sampleRate: process.env.REACT_APP_BUILD_ENV === "production" ? 1 : 0.5,
    denyUrls: [
      // anything from localhost
      new RegExp("localhost", "gi"),
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      // Firefox extensions
      /^resource:\/\//i
    ],
    ignoreErrors: [
      // screen resizing, no-op
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications",
      // ignore the AntV error related to the tooltips
      "(reading 'appendChild')",
      // Random plugins/extensions
      "top.GLOBALS",
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      "originalCreateNotification",
      "canvas.contentDocument",
      "MyApp_RemoveAllHighlights",
      "http://tt.epicplay.com",
      "Can't find variable: ZiteReader",
      "jigsaw is not defined",
      "ComboSearch is not defined",
      "http://loading.retry.widdit.com/",
      "atomicFindClose",
      // Facebook borked
      "fb_xd_fragment",
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      "bmi_SafeAddOnload",
      "EBCallBackMessageReceived",
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      "conduitPage",
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      "Script error."
    ]
  });

/**
 * Init Sentry
 * @return {Promise<unknown>}
 */
export const initSentry = (): Promise<unknown> =>
  new Promise((resolve) => {
    if (window.location.hash !== "#no-sentry") {
      initialise();
    }

    resolve(true);
  });
