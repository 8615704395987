import { FC, ComponentProps, useState, useEffect } from "react";
import * as _ from "lodash";
import { withProps } from "hoc-with-props";

import {
  Subscriptions as Content,
  useTranslation,
  supportedLocales,
  SupportedLocaleKeys,
  SupportedLocales
} from "@ctra/i18n";

import {
  Button,
  Flag,
  DownOutlined,
  Dropdown,
  LoadingOutlined,
  Menu,
  Icon,
  Typography,
  Space
} from "@ctra/components";

import { useLocalization } from "@base";

import { GACategories } from "../../analytics";
import styles from "./LanguageSelector.module.less";

const { Text } = Typography;

interface UserPreferencesProps {
  locales?: SupportedLocales;
}

/**
 * User preference selector
 * @constructor
 */
export const LanguageSelector: FC<UserPreferencesProps> = ({ locales = supportedLocales }) => {
  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const { locale, updateLocale } = useLocalization();

  /**
   * i18n to be in sync with locale from user prefs
   */
  useEffect(() => {
    if (locale && i18n.language !== locale) {
      setLoading(true);
      i18n.changeLanguage(locale);
    }
  }, [locale, i18n]);

  /**
   * register on mount an event listener for when language is changed
   * this help us visualize the loading state
   * unregister on unmount
   */
  useEffect(() => {
    i18n.on("languageChanged", () => setLoading(false));
    return () => {
      i18n.off("languageChanged");
    };
  }, [i18n]);

  /**
   * Locale keys that are to be mapped
   */
  const localeKeys = _.keys(locales) as Array<SupportedLocaleKeys>;

  /**
   * Language change handler
   */
  const onLanguageChange = ({ key }: { key: SupportedLocaleKeys }) => {
    setLoading(true);
    updateLocale(key);
    i18n.changeLanguage(key);
  };

  /**
   * get the title with the given country code
   */
  const getTitle = (locale: SupportedLocaleKeys) => locales[locale].title;

  /**
   * Dropdown toggle button
   */
  const button = (
    <Button
      className={styles.Base}
      data-gtm-category={GACategories.languageSelector}
      data-gtm-action="Open language selector"
      // @ts-ignore - the flag component will be fine
      icon={<Icon component={withProps({ countryCode: locales[locale].countryCode })(Flag)} />}
    >
      <Space size="small">
        <Text>{getTitle(locale)}</Text>
        {loading ? <LoadingOutlined /> : <DownOutlined />}
      </Space>
    </Button>
  );

  /**
   * Menu overlay for dropdown
   */
  const overlay = (
    <Menu
      onClick={(e) => {
        onLanguageChange({ key: e.key as SupportedLocaleKeys }) as ComponentProps<typeof Menu>["onClick"];
      }}
      selectedKeys={[locale]}
    >
      <Menu.ItemGroup title={t<string>(Content.base.languageSelector.label)} className={styles.Group}>
        {localeKeys.map((value) => (
          <Menu.Item
            data-gtm-category={GACategories.languageSelector}
            data-gtm-action="Select language"
            data-gtm-info={JSON.stringify({
              locale: locales[value]
            })}
            key={value}
            icon={
              <Icon
                // @ts-ignore - the flag component will be fine
                component={withProps({
                  countryCode: locales[value].countryCode
                })(Flag)}
              />
            }
          >
            <Text>{getTitle(value)}</Text>
          </Menu.Item>
        ))}
      </Menu.ItemGroup>
    </Menu>
  );

  return localeKeys.length > 1 ? (
    <Dropdown trigger={["click"]} dropdownRender={() => overlay} placement={"bottomLeft"}>
      {button}
    </Dropdown>
  ) : null;
};
