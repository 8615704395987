import { FC, useState } from "react";
import { Formik } from "formik";
import * as _ from "lodash";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";

import { CtraLayout, Typography, Col, Button, Row, LeftOutlined, Skeleton, Alert } from "@ctra/components";
import { useTranslation, Subscriptions as Content } from "@ctra/i18n";
import { useDidMount } from "@ctra/utils";
import { SignupState, Subscriptions, SubscriptionsAppState, DHMSDetails } from "@ctra/api";

import { Routes } from "@routes";
import { useGettingStarted } from "@getting-started";

import { dhmsFormKey, farmDetailsFormKey } from "../const";
import { DHMSForm } from "../DHMSForm";
import { GACategories } from "../../analytics";

const { Title, Text } = Typography;
const { WidgetWrapper, ContentWrapper } = CtraLayout;

/**
 * DHMS details page
 * @return {JSX.Element}
 * @constructor
 */
export const DHMSDetailsPage: FC = () => {
  const { t } = useTranslation();
  const [mayRedirect, setMayRedirect] = useState<boolean>(false);
  const [isMounting] = useDidMount();

  const {
    api: {
      farmDetails: { update: updateFarmDetails }
    },
    meta: {
      farmDetails: { fetch, update }
    }
  } = useGettingStarted();

  const {
    gettingStarted: {
      next,
      prev,
      dhms: { title, description, alert, form }
    }
  } = Content;

  /**
   * Signup state
   * @type {SignupState}
   */
  const signupState = useSelector<SubscriptionsAppState, SignupState>(Subscriptions.entities.getSignupValues);

  /**
   * Tell if the DHMS details are saved
   * @type {boolean}
   */
  const saved = mayRedirect && !isMounting && !update.pending;

  return saved ? (
    <Redirect to={Routes.app.gettingStarted.selectRole.owner.accountDetails} />
  ) : (
    <WidgetWrapper
      title={
        <section>
          <Title level={5}>{t<string>(title)}</Title>
          <Text>{t<string>(description)}</Text>
        </section>
      }
    >
      <Skeleton loading={!fetch.dispatched || fetch.pending}>
        <Formik<DHMSDetails>
          initialValues={_.merge(
            {
              dhms: "",
              notification: false,
              other: ""
            },
            _.get(signupState, [dhmsFormKey], {})
          )}
          validationSchema={Yup.object().shape({
            dhms: Yup.string().required(t<string>(form.required)),
            other: Yup.string().when("dhms", {
              is: (dhms: string) => dhms === "other",
              then: Yup.string().required(t<string>(form.required))
            })
          })}
          onSubmit={(values, { setSubmitting }) => {
            updateFarmDetails(_.pick(signupState, [dhmsFormKey, farmDetailsFormKey]));
            setMayRedirect(true);
            setSubmitting(false);
          }}
        >
          {({ submitForm }) => (
            <>
              <ContentWrapper title={t<string>(form.title)} style={{ marginBottom: "16px" }}>
                <Alert message={t<string>(alert)} type="info" showIcon style={{ marginBottom: "16px" }} />
                <DHMSForm />
              </ContentWrapper>
              <Row gutter={[16, 0]}>
                <Col>
                  <Button
                    data-gtm-category={GACategories.gettingStarted}
                    data-gtm-action="Go to 'Farm details'"
                    icon={<LeftOutlined />}
                    onClick={() =>
                      Subscriptions.history.push(Routes.app.gettingStarted.selectRole.owner.farmDetails)
                    }
                  >
                    {t<string>(prev)}
                  </Button>
                </Col>
                <Col>
                  <Button
                    data-gtm-category={GACategories.gettingStarted}
                    data-gtm-action="Submit DHMS details"
                    type="primary"
                    onClick={submitForm}
                  >
                    {t<string>(next)}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Formik>
      </Skeleton>
    </WidgetWrapper>
  );
};
