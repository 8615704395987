import { FC } from "react";
import { withRouter, RouteComponentProps, Link, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  Menu,
  Badge,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MessageOutlined,
  InfoCircleOutlined
} from "@ctra/components";

import { Subscriptions as Content, useTranslation } from "@ctra/i18n";
import { classname, CookieKeys, Cookies } from "@ctra/utils";
import { Session, Subscriptions } from "@ctra/api";

import { Routes } from "@routes";

import { GACategories } from "../../analytics";
import { intercomLauncherClassName, useIntercom } from "../../providers";

import styles from "./Navigation.module.less";

type NavigationProps = RouteComponentProps & {
  /**
   * callback for toggling the collapsed state
   * @param {boolean} collapsed
   */
  onToggle?: (collapsed: boolean) => void;
  /**
   * collapsed state of the navigation controlled by sider
   */
  collapsed?: boolean;
};

/**
 * Main sidebar navigation
 * @type {React.ComponentClass<Omit<NavigationProps, keyof RouteComponentProps<any>> & WithRouterProps<React.FC<NavigationProps>>> & WithRouterStatics<React.FC<NavigationProps>>}
 */
export const Navigation = withRouter<NavigationProps, FC<NavigationProps>>(
  ({ location: { pathname }, collapsed, onToggle = () => void 0 }) => {
    const { t } = useTranslation();
    const { isChatOpen, unreadCount } = useIntercom();
    const dispatch = useDispatch();

    /**
     * Route match for the getting started section
     * @type {match<{}> | null}
     */
    const gettingStarted = useRouteMatch(`${Routes.app.gettingStarted.index}*`);

    return (
      <>
        <Menu
          mode="inline"
          selectedKeys={[pathname]}
          className={classname(styles.Wrapper)}
          selectable={false}
          triggerSubMenuAction="click"
          style={{ display: "flex", flexDirection: "column", flex: "1 1 auto" }}
          inlineIndent={14}
        >
          <Menu.Item key={gettingStarted?.url}>
            <Link to={Routes.app.gettingStarted.index}>
              {t<string>(Content.navigation.main.gettingStarted)}
            </Link>
          </Menu.Item>
          <Menu.Item
            className={styles.Collapse}
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => onToggle(!collapsed)}
          >
            {t<string>(Content.navigation.main.collapse, { status: collapsed ? "collapsed" : "expanded" })}
          </Menu.Item>
        </Menu>
        <Menu
          mode="inline"
          selectedKeys={[pathname]}
          className={classname(styles.Wrapper)}
          selectable={false}
          triggerSubMenuAction="click"
          style={{ marginTop: "auto" }}
          inlineIndent={14}
        >
          <Menu.Divider />
          {t<string>(Content.navigation.main.helpCenter) && (
            <Menu.Item icon={<InfoCircleOutlined />} key="helpcenter">
              <a
                data-gtm-category={GACategories.navigation}
                data-gtm-action="Open Help Center"
                href="https://intercom.help/connecterra"
                rel="noopener noreferrer"
                onClick={() => {
                  window.open("https://intercom.help/connecterra", "_blank");
                }}
                target={"_blank"}
              >
                {t<string>(Content.navigation.main.helpCenter)}
              </a>
            </Menu.Item>
          )}
          <Menu.Item
            className={classname(intercomLauncherClassName, isChatOpen ? styles.Chat : styles.ChatUnselected)}
            data-gtm-category={GACategories.navigation}
            data-gtm-action="Open Chat"
            icon={
              <Badge count={unreadCount}>
                <MessageOutlined />
              </Badge>
            }
            key={"chat"}
          >
            {t<string>(Content.navigation.main.support)}
          </Menu.Item>
          <Menu.Item
            data-gtm-category={GACategories.navigation}
            data-gtm-action="Logout"
            onClick={() => {
              // set the referrer cookie to platform (when logging out)
              // so we never come back to this app again
              Cookies.set(CookieKeys.referrer, "platform");
              dispatch(Session.actions.logout());
              Subscriptions.history.push(Routes.auth.login);
            }}
            icon={<LogoutOutlined />}
          >
            {t<string>(Content.navigation.main.logout)}
          </Menu.Item>
        </Menu>
      </>
    );
  }
);
