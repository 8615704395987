export const gettingStarted = {
  _root: "/getting-started",
  selectRole: {
    _root: "/select-role",
    owner: {
      _root: "/owner",
      accountDetails: "/account-details",
      farmDetails: "/farm-details",
      appointment: "/appointment",
      dataIntegration: "/data-integration",
      dhms: "/dhms"
    },
    other: "/other"
  }
};
