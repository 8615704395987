import { FC, useEffect, useState } from "react";
import { Formik } from "formik";
import * as _ from "lodash";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import * as Sentry from "@sentry/react";
import * as Yup from "yup";

import {
  CtraLayout,
  Typography,
  Button,
  Row,
  Col,
  LeftOutlined,
  Skeleton,
  AntDesignRadio as Radio,
  Space,
  Grid
} from "@ctra/components";

import { useTranslation, Subscriptions as Content } from "@ctra/i18n";
import { SignupState, Subscriptions, SubscriptionsAppState } from "@ctra/api";
import { isProduction, Nullable, useDidMount, Cookies } from "@ctra/utils";

import { useCurrentUser } from "@auth";
import { Routes } from "@routes";
import { useGettingStarted } from "@getting-started";

import { getStorageKey } from "../../../../utils/versioning";
import { GACategories } from "../../analytics";
import { accountDetailsFormKey, countries } from "../const";
import { AccountDetailsForm } from "../AccountDetailsForm";
import styles from "./AccountDetailsPage.module.less";

const { Title, Text } = Typography;
const { WidgetWrapper, ContentWrapper } = CtraLayout;

export interface AccountDetailsFormValues {
  firstName: string;
  lastName: string;
  country: string;
  email: Nullable<string>;
  phoneNumber: string;
}

/**
 * Account details page
 * @return {JSX.Element}
 * @constructor
 */
export const AccountDetailsPage: FC = () => {
  const { t } = useTranslation();
  const [mayRedirect, setMayRedirect] = useState<boolean>(false);
  const [tier, setTier] = useState<"free" | "paid">("free");
  const [isMounting] = useDidMount();
  const { user } = useCurrentUser();
  const Chargebee = _.get(window, "Chargebee", null);
  const { md } = Grid.useBreakpoint();

  const {
    api: {
      accountDetails: { update: updateAccountDetails }
    },
    meta: {
      accountDetails: { fetch, update }
    }
  } = useGettingStarted();

  const {
    gettingStarted: {
      next,
      prev,
      accountDetails: { title, description, contactDetails, subscription }
    }
  } = Content;

  useEffect(() => {
    const tier = Cookies.get(`${getStorageKey()}.tier`) as Nullable<"free" | "paid">;

    if (tier) {
      setTier(tier);
    }
  }, []);

  /**
   * Signup state
   * @type {SignupState}
   */
  const signupState = useSelector<SubscriptionsAppState, SignupState>(Subscriptions.entities.getSignupValues);

  /**
   * Handle form submit and ask for credit card details
   * @param {AccountDetailsFormValues} values
   * @return {Promise<void>}
   */
  const submitWithChargebee = (values: AccountDetailsFormValues) =>
    new Promise<void>((resolve, reject) => {
      const { firstName, lastName, email } = values;

      if (_.isFunction(Chargebee.getInstance)) {
        const cbInstance = Chargebee.getInstance();
        const cart = cbInstance.getCart();

        const customer = {
          first_name: firstName,
          last_name: lastName,
          email: email,
          billing_address: {
            first_name: firstName,
            last_name: lastName
          }
        };

        cart.setCustomer(customer);

        /**
         * Set a callback for successful payment
         */
        cbInstance.setCheckoutCallbacks(() => ({
          /**
           * When the payment is successful
           */
          success: () => {
            resolve();
          },
          /**
           * When the payment is not successful
           */
          error: () => {
            reject();
          }
        }));
      } else {
        Sentry.captureException("Chargebee.getInstance is not a function, unable to send user details.", {
          tags: {
            diagnostics: "chargebeeError"
          }
        });
      }

      const paymentTrigger = document.querySelector("#cb-subscribe");

      if (paymentTrigger) {
        paymentTrigger.dispatchEvent(
          new MouseEvent("click", {
            bubbles: true,
            cancelable: true
          })
        );
      } else {
        Sentry.captureException("Chargebee payment trigger not found, unable to show dialog.", {
          tags: {
            diagnostics: "chargebeeError"
          }
        });
      }
    });

  /**
   * Tell if the values are saved
   * @type {boolean}
   */
  const saved = mayRedirect && !isMounting && !update.pending;

  /**
   * Tell if the app is running in a mobile app
   * @type {any}
   */
  // @ts-ignore
  const isMobileApp = !window || !md || window.CTRA_MOBILE_WRAPPER;

  return saved ? (
    <Redirect to={Routes.app.gettingStarted.selectRole.owner.appointment} />
  ) : (
    <WidgetWrapper
      title={
        <section>
          <Title level={5}>{t<string>(title)}</Title>
          <Text>{t<string>(description)}</Text>
        </section>
      }
    >
      <Skeleton loading={!fetch.dispatched || fetch.pending}>
        <Formik<AccountDetailsFormValues>
          initialValues={_.merge(
            {
              firstName: "",
              lastName: "",
              country: "",
              email: user.username,
              phoneNumber: ""
            },
            _.get(signupState, [accountDetailsFormKey], {})
          )}
          validationSchema={Yup.object().shape({
            firstName: Yup.string().nullable().required(t<string>(contactDetails.form.required)),
            lastName: Yup.string().nullable().required(t<string>(contactDetails.form.required)),
            country: isProduction()
              ? Yup.string()
                  .oneOf([...countries, "Russia"], t<string>(contactDetails.form.unknownCountry))
                  .nullable()
                  .required(t<string>(contactDetails.form.required))
              : Yup.string()
                  .test(
                    "is-russia",
                    "Did you mean: Russian Federation",
                    (country) => _.lowerCase(country) !== "russia"
                  )
                  .oneOf([...countries, "Russian Federation"], t<string>(contactDetails.form.unknownCountry))
                  .nullable()
                  .required(t<string>(contactDetails.form.required)),
            email: Yup.string().nullable().required(t<string>(contactDetails.form.required)),
            phoneNumber: Yup.string().nullable().required(t<string>(contactDetails.form.required))
          })}
          onSubmit={(value, { setSubmitting }) => {
            Cookies.set(`${getStorageKey()}.tier`, tier);

            if (tier === "free") {
              updateAccountDetails(_.pick(signupState, accountDetailsFormKey));
              setSubmitting(false);
              setMayRedirect(true);
            } else {
              submitWithChargebee(value)
                .then(() => {
                  updateAccountDetails(_.pick(signupState, accountDetailsFormKey));
                  setMayRedirect(true);
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }
          }}
        >
          {({ submitForm }) => (
            <>
              <ContentWrapper title={t<string>(contactDetails.title)} className={styles.Margin}>
                <AccountDetailsForm />
              </ContentWrapper>
              {isMobileApp ? null : (
                <ContentWrapper title={t<string>(subscription.title)} className={styles.Margin}>
                  <Radio.Group onChange={(e) => setTier(e.target.value)} value={tier}>
                    <Space direction="vertical">
                      <Radio
                        data-gtm-category={GACategories.gettingStarted}
                        data-gtm-action="Tier 'free'"
                        value="free"
                      >
                        <Space direction="vertical" size={2}>
                          <Text>{t<string>(subscription.tier.title, { type: "free" })}</Text>
                          <Text type="secondary" style={{ fontSize: "14px" }}>
                            {t<string>(subscription.tier.description, { type: "free" })}
                          </Text>
                        </Space>
                      </Radio>
                      <Radio
                        data-gtm-category={GACategories.gettingStarted}
                        data-gtm-action="Tier 'paid'"
                        value="paid"
                      >
                        <Space direction="vertical" size={2}>
                          <Text>{t<string>(subscription.tier.title, { type: "paid" })}</Text>
                          <Text type="secondary" style={{ fontSize: "14px" }}>
                            {t<string>(subscription.tier.description, { type: "paid" })}
                          </Text>
                        </Space>
                      </Radio>
                    </Space>
                  </Radio.Group>
                </ContentWrapper>
              )}
              <Row gutter={[16, 0]}>
                <Col>
                  <Button
                    data-gtm-category={GACategories.gettingStarted}
                    data-gtm-action="Go to 'select role'"
                    icon={<LeftOutlined />}
                    onClick={() =>
                      Subscriptions.history.push(Routes.app.gettingStarted.selectRole.owner.dhms)
                    }
                  >
                    {t<string>(prev)}
                  </Button>
                </Col>
                <Col>
                  <Button
                    data-gtm-category={GACategories.gettingStarted}
                    data-gtm-action="Submit account details"
                    type="primary"
                    onClick={submitForm}
                  >
                    {t<string>(next)}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Formik>
      </Skeleton>
    </WidgetWrapper>
  );
};
