import { FC } from "react";
import * as _ from "lodash";
import { Redirect, Route, RouteProps, RouteComponentProps, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import * as Sentry from "@sentry/react";

import { SubscriptionsAppState, Subscriptions } from "@ctra/api";

const SentryRoute = Sentry.withSentryRouting(Route);

interface ProtectedRouteProps extends RouteProps {
  redirect: string;
}

/**
 * Protected route which will redirect you to a given route if you are not logged in.
 * @param {ProtectedRouteProps} props
 */
export const ProtectedRoute: FC<ProtectedRouteProps> = (props: ProtectedRouteProps) => {
  const { render, redirect, component: Component } = props;
  const location = useLocation();

  /**
   * Tell whether the user is logged in.
   */
  const isLoggedIn = useSelector<SubscriptionsAppState, boolean>(Subscriptions.entities.isLoggedIn);

  /**
   * Tell if the user logged out themselves
   */
  const hasLoggedOut = useSelector<SubscriptionsAppState, boolean>(Subscriptions.entities.hasLoggedOut);

  return (
    <SentryRoute
      render={(props: RouteComponentProps) => {
        if (isLoggedIn) {
          let target;

          if (_.isFunction(render)) {
            target = render(props);
          } else if (Component) {
            target = <Component {...props} />;
          } else {
            throw new Error("You need a component or a render prop for this route.");
          }

          return target;
        }

        return (
          <Redirect
            push
            to={{
              pathname: redirect,
              search: location.search,
              hash: location.hash,
              state: hasLoggedOut
                ? {}
                : {
                    protectedLocation: location
                  }
            }}
          />
        );
      }}
    />
  );
};
