import { buildRoutes, compileRoutes } from "@ctra/utils";

import { base } from "@base";
import { signup } from "@signup";
import { auth } from "@auth";
import { gettingStarted } from "@getting-started";

/**
 * Route strings for Subscriptions
 * @note the weird looking destructuring actually helps getting rid of circular imports in tests, no idea how
 */
export const Routes = buildRoutes({
  _root: "",
  auth,
  signup,
  external: {
    _root: "/external",
    enterprise: "/enterprise"
  },
  app: {
    ...base,
    gettingStarted
  }
});

/**
 * Compiled route strings which are callable. It comes handy during
 * navigation as you do not need to individually compile any path.
 * @example
 * ```ts
 * assert(Routes.app.farms.farm === "/app/farms/:farmID/");
 * CompiledRoutes.app.farms.farm({ farmID: 1 });
 * // "/app/farms/1/"
 * ```
 */
export const CompiledRoutes = compileRoutes(Routes);
