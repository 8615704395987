/* eslint @typescript-eslint/no-non-null-assertion: 0 */

import { FC, useEffect, useState } from "react";
import * as _ from "lodash";
import { useCalendlyEventListener, PopupModal, InlineWidget } from "react-calendly";

import {
  CtraLayout,
  Typography,
  Col,
  Button,
  LeftOutlined,
  Row,
  Alert,
  useCalendlyAppointment
} from "@ctra/components";

import { Subscriptions as Content, useTranslation } from "@ctra/i18n";
import { Subscriptions } from "@ctra/api";
import { CookieKeys, Cookies, TS } from "@ctra/utils";

import { useGettingStarted } from "@getting-started";
import { Routes } from "@routes";
import { useCurrentUser } from "@auth";

import { redirectURL } from "../../../../utils/url";
import { GACategories } from "../../analytics";
import styles from "./AppointmentPage.module.less";

const { WidgetWrapper, ContentWrapper } = CtraLayout;
const { Title, Text } = Typography;

const {
  gettingStarted: {
    prev,
    final,
    appointment: { title: appointmentTitle, description: appointmentDescription, content: appointmentContent }
  }
} = Content;

/**
 * Appointment page
 * @return {JSX.Element}
 * @constructor
 */
export const AppointmentPage: FC<{ widget?: "modal" | "embed" }> = ({ widget = "modal" }) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const {
    meta: {
      signupDetails: {
        update: { fulfilled: isSignupDetailsUpdated }
      }
    },
    api: {
      signupDetails: { update: updateSignupDetails }
    }
  } = useGettingStarted();

  const {
    event,
    api: { setEvent }
  } = useCalendlyAppointment();

  useEffect(() => {
    if (isSignupDetailsUpdated && event?.start_time) {
      goToApp();
    }
  }, [isSignupDetailsUpdated, event]);

  /**
   * Handle scheduled event, move to next page
   */
  useCalendlyEventListener({
    onEventScheduled: ({
      data: {
        payload: {
          event: { uri }
        }
      }
    }) => {
      setEvent(_.split(uri, "/").pop() as string);
      updateSignupDetails({ role: "Farmer", isSignupCompleted: true });
    }
  });

  const {
    state: {
      accountDetails: { firstName, lastName, phoneNumber }
    }
  } = useGettingStarted();

  const { user } = useCurrentUser();

  /**
   * Go to the main app
   */
  const goToApp = () => {
    if (window) {
      Cookies.remove(CookieKeys.referrer);
      window.open(redirectURL, "_self");
    }
  };

  return (
    <WidgetWrapper
      className={styles.MarginLarge}
      title={
        <section>
          <Title level={5}>{t<string>(appointmentTitle)}</Title>
          <Text>{t<string>(appointmentDescription)}</Text>
        </section>
      }
    >
      <ContentWrapper
        className={styles.Margin}
        title={widget === "modal" ? void 0 : t<string>(appointmentContent.title)}
      >
        {event ? (
          <Alert
            showIcon
            type="success"
            message={t<string>(appointmentContent.description, {
              dateTime: TS.asMoment(event.start_time, {
                parseZone: true
              })
                .local()
                .format("LLL")
            })}
          />
        ) : null}
        {widget === "modal" ? (
          <>
            <Button
              data-gtm-category={GACategories.gettingStarted}
              data-gtm-action="Make a new appointent"
              type="primary"
              size="large"
              onClick={() => setIsModalVisible(true)}
            >
              {t<string>(appointmentContent.title, {
                hasScheduledEvent: !_.isEmpty(event)
              })}
            </Button>
            <PopupModal
              pageSettings={{
                hideGdprBanner: true
              }}
              prefill={{
                email: _.defaultTo(user.email, void 0),
                firstName: firstName,
                lastName: lastName,
                name: `${firstName} ${lastName}`,
                smsReminderNumber: phoneNumber
              }}
              url="https://calendly.com/d/42b-rkd-zb2/data-integration-call"
              onModalClose={() => setIsModalVisible(false)}
              open={isModalVisible}
              rootElement={document.getElementById("root")!}
            />
          </>
        ) : (
          <InlineWidget
            pageSettings={{
              hideGdprBanner: true
            }}
            styles={{
              position: "relative",
              minWidth: "320px",
              height: "750px"
            }}
            prefill={{
              email: _.defaultTo(user.email, void 0),
              firstName: firstName,
              lastName: lastName,
              name: `${firstName} ${lastName}`,
              smsReminderNumber: phoneNumber
            }}
            url="https://calendly.com/d/42b-rkd-zb2/data-integration-call"
          />
        )}
      </ContentWrapper>
      <Row gutter={[16, 0]}>
        <Col>
          <Button
            data-gtm-category={GACategories.gettingStarted}
            data-gtm-action="Go to 'account details'"
            icon={<LeftOutlined />}
            onClick={() =>
              Subscriptions.history.push(Routes.app.gettingStarted.selectRole.owner.accountDetails)
            }
          >
            {t<string>(prev)}
          </Button>
        </Col>
        {!!event ? (
          <Col>
            <Button
              data-gtm-category={GACategories.gettingStarted}
              data-gtm-action="Go to the Connecterra Platform App"
              type="primary"
              onClick={goToApp}
            >
              {t<string>(final)}
            </Button>
          </Col>
        ) : null}
      </Row>
    </WidgetWrapper>
  );
};
