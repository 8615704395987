import { FC, useState, createContext, useContext } from "react";
import * as _ from "lodash";

import { SupportedLocaleKeys, storeAppLocale, loadAppLocale } from "@ctra/i18n";

interface ContextType {
  locale: SupportedLocaleKeys;
  updateLocale: (locale: SupportedLocaleKeys) => void;
}

/**
 * Make default context for unit system
 */
const DefaultContext = createContext<ContextType>({
  locale: loadAppLocale(),
  updateLocale: _.noop
});

/**
 * Provide the localization context to the translation system and the app
 * @param children
 * @private
 */
const _LocalizationContextProvider: FC = ({ children }) => {
  const [locale, setLocale] = useState<SupportedLocaleKeys>(loadAppLocale());

  /**
   * Set locale in the app:
   * set local state, local storage and user preferences
   * @param locale
   */
  const updateLocale = (locale: SupportedLocaleKeys) => {
    setLocale(loadAppLocale(locale));
    storeAppLocale(locale);
  };

  return (
    <DefaultContext.Provider
      value={{
        locale,
        updateLocale
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const LocalizationContext = {
  Provider: _LocalizationContextProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Hook to get the unit system and setter from within the context
 */
export const useLocalization = (): ContextType => useContext(DefaultContext);
