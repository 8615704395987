import { FC } from "react";
import { useSelector } from "react-redux";
import { NavLink, Route, Switch, useLocation } from "react-router-dom";
import _ from "lodash";

import {
  Breadcrumb,
  Space,
  Grid,
  CtraLogo,
  LeftOutlined,
  CaretRightOutlined,
  Button
} from "@ctra/components";

import { BreadcrumbList, Subscriptions, SubscriptionsAppState } from "@ctra/api";

import { LanguageSelector } from "@base";
import { Routes } from "@routes";

import style from "./Header.module.less";

/**
 * Application header with breadcrumbs and user menu
 * @constructor
 */
export const Header: FC = () => {
  const { md } = Grid.useBreakpoint();
  const { search } = useLocation();

  /**
   * Breadcrumbs from the state
   * @type {BreadcrumbList}
   */
  const breadcrumbs = useSelector<SubscriptionsAppState, BreadcrumbList>(
    Subscriptions.entities.getBreadcrumbs
  );

  /**
   * Back button
   * @type {JSX.Element}
   */
  const backButton = (
    <section className={style.Branding}>
      <Button type="primary" onClick={Subscriptions.history.goBack} icon={<LeftOutlined />} />
    </section>
  );

  return (
    <>
      {md ? (
        <Breadcrumb className={style.Breadcrumb} separator={<CaretRightOutlined />}>
          {breadcrumbs.map(({ id, title, path }) => (
            <Breadcrumb.Item key={id}>{path ? <NavLink to={path}>{title}</NavLink> : title}</Breadcrumb.Item>
          ))}
        </Breadcrumb>
      ) : (
        <>
          {_.isEmpty(search) ? (
            <Switch>
              {/* Render the Ida logo on all parent index pages */}
              <Route exact path={[Routes.app.index]}>
                <section className={style.Branding}>
                  <CtraLogo />
                </section>
              </Route>
              {/* In any other case, render the back button */}
              <Route>{backButton}</Route>
            </Switch>
          ) : (
            backButton
          )}
        </>
      )}
      <Space className={style.PullRight} size="middle">
        {md && <LanguageSelector />}
      </Space>
    </>
  );
};
