import { createContext, FC, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";

import { RemoteSession, Signup, SubscriptionsAppState, Subscriptions } from "@ctra/api";
import { isDispatched, isFulfilled, isPending } from "@ctra/utils";

interface ContextType {
  api: {
    createRemoteSession: () => void;
  };
  meta: {
    isCreated: boolean;
    isLoading: boolean;
    isCreating: boolean;
  };
  session: Partial<RemoteSession>;
}

const DefaultContext = createContext<ContextType>({
  api: {
    createRemoteSession: _.noop
  },
  meta: {
    isCreated: false,
    isLoading: false,
    isCreating: false
  },
  session: {}
});

/**
 * Data integration context provider
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
 * @return {JSX.Element}
 */
const _DataIntegrationContextProvider: FC = ({ children }) => {
  const dispatch = useDispatch();

  /**
   * Get the remote session from the store
   * @type {RemoteSession}
   */
  const remoteSession = useSelector<SubscriptionsAppState, RemoteSession>((state) =>
    Subscriptions.entities.getRemoteSession(state)
  );

  /**
   * Check if the remote session has been dispatched
   * @type {boolean}
   */
  const hasDispatched = useSelector<SubscriptionsAppState, boolean>((state) =>
    isDispatched(state, Signup.types.GET_REMOTE_SESSION)
  );

  /**
   * Check if the remote session is loading
   * @type {boolean}
   */
  const isLoading = useSelector<SubscriptionsAppState, boolean>((state) =>
    isPending(state, Signup.types.GET_REMOTE_SESSION)
  );

  /**
   * Check if the remote session is being made
   * @type {boolean}
   */
  const isCreatingRemoteSession = useSelector<SubscriptionsAppState, boolean>((state) =>
    isPending(state, Signup.types.MAKE_REMOTE_SESSION)
  );

  /**
   * Check if the remote session has been created
   * @type {boolean}
   */
  const created = useSelector<SubscriptionsAppState, boolean>((state) =>
    isFulfilled(state, Signup.types.MAKE_REMOTE_SESSION)
  );

  useEffect(() => {
    if (!hasDispatched) {
      dispatch(Signup.actions.getRemoteSession.start());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDispatched]);

  return (
    <DefaultContext.Provider
      value={{
        api: {
          createRemoteSession: () => dispatch(Signup.actions.makeRemoteSession.start())
        },
        meta: { isLoading, isCreating: isCreatingRemoteSession, isCreated: created },
        session: remoteSession
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const DataIntegrationContext = {
  Provider: _DataIntegrationContextProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Use data integration
 * @return {ContextType}
 */
export const useDataIntegration = (): ContextType => useContext(DefaultContext);
