import { FC } from "react";
import { FormikFormProps, FormikProps } from "formik";
import { useSelector } from "react-redux";
import * as _ from "lodash";

import { Auth as AuthUI, Typography } from "@ctra/components";
import { SubscriptionsAppState, UserSignupFormValues, Auth } from "@ctra/api";
import { useTranslation, Subscriptions as Content, parseLocoTemplate } from "@ctra/i18n";
import { isPending, isRejected } from "@ctra/utils";

import { GACategories } from "../../analytics";

const { SignupForm: SignupFormUI } = AuthUI;
const { Text } = Typography;

export type SignupFormProps = FormikProps<UserSignupFormValues> & FormikFormProps;

/**
 * Sign up form
 * @constructor
 */
export const SignupForm: FC<SignupFormProps> = () => {
  const { t } = useTranslation();
  const { labels, eula, signupFailed, placeholders } = Content.signup.form;

  /**
   * Tell if the signup was not successful
   */
  const [isSignupRejected, payload] = useSelector<
    SubscriptionsAppState,
    [
      boolean,
      {
        error: string;
        statusCode: number;
      }
    ]
  >((state) =>
    isRejected(state, Auth.types.SIGN_UP_USER, {
      withPayload: true
    })
  );

  /**
   * Tell whether the signup action is in progress
   */
  const isLoading = useSelector<SubscriptionsAppState, boolean>((state) =>
    isPending(state, Auth.types.SIGN_UP_USER)
  );

  const [pre, EULA, conjunction, privacyStatement, post] = _.defaultTo(parseLocoTemplate(t(eula)), []);

  /**
   * EULA label
   * @type {JSX.Element}
   */
  const eulaLabel = (
    <Text>
      {pre}
      <a
        data-gtm-category={GACategories.signup}
        data-gtm-action="View EULA"
        href="https://4324635.fs1.hubspotusercontent-na1.net/hubfs/4324635/Connecterra%20EULA_2023.pdf"
        target="_blank"
        rel="noreferrer"
      >
        {EULA}
      </a>
      {conjunction}
      <a
        data-gtm-category={GACategories.signup}
        data-gtm-action="View Privacy Statement"
        href="https://connecterra.ai/privacy-policy/"
        target="_blank"
        rel="noreferrer"
      >
        {privacyStatement}
      </a>
      {post}
    </Text>
  );

  return (
    <SignupFormUI
      labels={{
        email: t<string>(placeholders.email),
        firstName: t<string>(placeholders.firstName),
        lastName: t<string>(placeholders.lastName),
        phoneNumber: t<string>(placeholders.phoneNumber),
        password: t<string>(placeholders.password),
        confirmPassword: t<string>(placeholders.confirmPassword),
        create: t<string>(labels.create),
        rejected: isSignupRejected
          ? t<string>(signupFailed, {
              originalError: _.defaultTo(payload.error, null)
            })
          : void 0,
        eula: eulaLabel
      }}
      isLoading={isLoading}
    />
  );
};
