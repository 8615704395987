import { FC, useEffect, createContext, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isDispatched } from "@ctra/utils";

import { Subscriptions, SubscriptionsAppState, User, UserEntity } from "@ctra/api";

interface ContextType {
  user: Partial<UserEntity>;
  meta: { isLoading: boolean };
}

/**
 * Make a default context for user entities
 */
const DefaultContext = createContext<ContextType>({
  user: {},
  meta: { isLoading: true }
});

/**
 * Fetch the current user object if it is not yet present in the store
 * @param children
 * @private
 */
const _UserProvider: FC = ({ children }) => {
  const dispatch = useDispatch();

  /**
   * Get the currently logged in username from the auth state
   */
  const username = useSelector<SubscriptionsAppState, UserEntity["username"]>(
    Subscriptions.entities.getUsername
  );

  /**
   * Attempt to get the user object
   */
  const user = useSelector<SubscriptionsAppState, UserEntity | undefined>((state) =>
    username ? Subscriptions.entities.getUser(state, { email: username }) : void 0
  );

  /**
   * Tell whether the fetching action has been dispatched
   */
  const dispatched = useSelector<SubscriptionsAppState, boolean>((state) =>
    isDispatched(state, User.types.FETCH_USER)
  );

  useEffect(() => {
    if (!(dispatched || user)) {
      dispatch(User.actions.fetchUser.start(username as string));
    }
  }, [dispatch, dispatched, user, username]);

  return (
    <DefaultContext.Provider
      value={{
        user: user || {
          username
        },
        meta: { isLoading: !user }
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const UserContext = {
  Consumer: DefaultContext.Consumer,
  Provider: _UserProvider
};

/**
 * Hook to get the user info within the context
 */
export const useCurrentUser = (): ContextType => useContext(DefaultContext);
