import store, { history, persistor } from "./store";
import * as entities from "./entities";

export * from "./state";

export const Subscriptions = {
  store,
  history,
  persistor,
  entities
};
