export enum GACategories {
  signup = "Sign up",
  confirmEmail = "Confirm email",
  activation = "Activation",
  farmDetails = "Farm details",
  dhms = "DHMS",
  accountDetails = "Account details",
  appointment = "Appointment",
  dataIntegration = "Data integration",
  selectRole = "Select role"
}
