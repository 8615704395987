import { FC, Suspense, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import * as Sentry from "@sentry/react";

import {
  CtraLayout,
  LoadingOutlined,
  Row,
  Spin,
  SpinnerContext,
  CalendlyAppointmentContext
} from "@ctra/components";

import { Breadcrumbs } from "@ctra/api";
import { useTranslation, Subscriptions as Content } from "@ctra/i18n";

import { Routes } from "@routes";
import { useLocalization, Header, IntercomContext, Navigation } from "@base";
import { useCurrentUser } from "@auth";
import { GettingStartedPage, GettingStartedContext } from "@getting-started";

const SentryRoute = Sentry.withSentryRouting(Route);
const { ApplicationWrapper } = CtraLayout;

const {
  components: { Breadcrumb }
} = Breadcrumbs;

/**
 * App component
 * @return {JSX.Element}
 * @constructor
 */
export const App: FC = () => {
  const { locale } = useLocalization();
  const { t } = useTranslation();
  const { user } = useCurrentUser();

  const {
    user: { username, email }
  } = useCurrentUser();

  /**
   * Hook up localization for Sentry
   */
  useEffect(() => {
    Sentry.setContext("localization", { locale });

    return () => {
      Sentry.setContext("localization", null);
    };
  }, [locale]);

  /**
   * Hook up the user context for Sentry
   */
  useEffect(() => {
    if (username && email) {
      Sentry.setUser({ username, email });
    }

    return () => {
      Sentry.configureScope((scope) => scope.clear());
    };
  }, [username, email]);

  return (
    <IntercomContext.Provider username={username}>
      <ApplicationWrapper
        floaters={<Breadcrumb title={t<string>(Content.navigation.main.gettingStarted)} />}
        sider={({ isCollapsed, toggle }) => <Navigation collapsed={isCollapsed} onToggle={toggle} />}
        header={<Header />}
      >
        <Suspense
          fallback={
            <Row justify="center" align="middle" style={{ height: "80vh" }}>
              <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />} />
            </Row>
          }
        >
          <SpinnerContext.Provider>
            <Switch>
              <SentryRoute exact path={Routes.app.gettingStarted.index}>
                <Redirect to={Routes.app.gettingStarted.selectRole.index} />
              </SentryRoute>
              <SentryRoute path={Routes.app.gettingStarted.selectRole.index}>
                <CalendlyAppointmentContext.Provider>
                  <GettingStartedContext.Provider>
                    <GettingStartedPage />
                  </GettingStartedContext.Provider>
                </CalendlyAppointmentContext.Provider>
              </SentryRoute>
              <SentryRoute>
                <Redirect to={Routes.app.gettingStarted.selectRole.index} />
              </SentryRoute>
            </Switch>
          </SpinnerContext.Provider>
        </Suspense>
      </ApplicationWrapper>
    </IntercomContext.Provider>
  );
};

export default App;
