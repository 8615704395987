import { FC } from "react";
import * as _ from "lodash";

import { useTranslation, Subscriptions as Content } from "@ctra/i18n";
import { AutoComplete, Form, Input } from "@ctra/components";
import { isProduction } from "@ctra/utils";

import { GACategories } from "../../analytics";
import { FormObserver } from "../FormObserver";
import { countries, farmDetailsFormKey } from "../const";
import styles from "./FarmDetailsForm.module.less";

/**
 * Farm details form
 * @return {JSX.Element}
 * @constructor
 */
export const FarmDetailsForm: FC = () => {
  const { t } = useTranslation();

  const {
    gettingStarted: {
      farmDetails: {
        form: { labels }
      }
    }
  } = Content;

  return (
    <section className={styles.Wrapper}>
      <FormObserver keyField={farmDetailsFormKey} />
      <Form layout="vertical">
        {_.map(_.omit(labels, "submit", "country"), (label, key) => (
          <Form.Item required key={key} name={key} label={t<string>(label)} hasFeedback={false}>
            <Input
              data-gtm-category={GACategories.gettingStarted}
              data-gtm-action={`Enter ${label}`}
              name={key}
            />
          </Form.Item>
        ))}
        <Form.Item required name="country" label={t<string>(labels.country)} hasFeedback={false}>
          <AutoComplete
            data-gtm-category={GACategories.gettingStarted}
            data-gtm-action="Select country"
            filterOption={(inputValue, option) =>
              option && _.isString(option.value)
                ? option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                : false
            }
            options={_.map(isProduction() ? [...countries, "Russia"] : countries, (country) => ({
              label: country,
              value: country
            }))}
            name="country"
          />
        </Form.Item>
      </Form>
    </section>
  );
};
