import { FC, createContext, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as _ from "lodash";

import { Farms, Signup, SignupState, Subscriptions, SubscriptionsAppState, User } from "@ctra/api";
import { isDispatched, isFulfilled, isPending } from "@ctra/utils";
import { Routes } from "@routes";

interface ContextType {
  state: SignupState;
  entry: string;
  api: {
    signupDetails: {
      update: (...params: Parameters<typeof User.actions.updateSignupDetails.start>) => void;
    };
    farmDetails: {
      fetch: () => void;
      update: (...params: Parameters<typeof Signup.actions.push>) => void;
    };
    accountDetails: {
      fetch: () => void;
      update: (...params: Parameters<typeof Signup.actions.push>) => void;
    };
  };
  meta: {
    signupDetails: {
      update: {
        dispatched: boolean;
        pending: boolean;
        fulfilled: boolean;
      };
    };
    farmDetails: {
      fetch: {
        dispatched: boolean;
        pending: boolean;
        fulfilled: boolean;
      };
      update: {
        dispatched: boolean;
        pending: boolean;
        fulfilled: boolean;
      };
    };
    accountDetails: {
      fetch: {
        dispatched: boolean;
        pending: boolean;
        fulfilled: boolean;
      };
      update: {
        dispatched: boolean;
        pending: boolean;
        fulfilled: boolean;
      };
    };
  };
}

const DefaultContext = createContext<ContextType>({
  state: {
    role: "",
    dhms: {},
    farmDetails: {},
    accountDetails: {},
    remoteSession: {}
  } as SignupState,
  entry: "",
  api: {
    signupDetails: {
      // fetch: _.noop,
      update: _.noop
    },
    farmDetails: {
      fetch: _.noop,
      update: _.noop
    },
    accountDetails: {
      fetch: _.noop,
      update: _.noop
    }
  },
  meta: {
    signupDetails: {
      update: {
        dispatched: false,
        pending: false,
        fulfilled: false
      }
    },
    farmDetails: {
      fetch: {
        dispatched: false,
        pending: false,
        fulfilled: false
      },
      update: {
        dispatched: false,
        pending: false,
        fulfilled: false
      }
    },
    accountDetails: {
      fetch: {
        dispatched: false,
        pending: false,
        fulfilled: false
      },
      update: {
        dispatched: false,
        pending: false,
        fulfilled: false
      }
    }
  }
});

/**
 * Context provider for the getting started flow
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
 * @return {JSX.Element}
 */
const _GettingStartedContextProvider: FC = ({ children }) => {
  const dispatch = useDispatch();

  /**
   * Get the farm details fetch status
   * @type {[boolean, boolean, boolean]}
   */
  const farmDetailsFetchStatus = useSelector<SubscriptionsAppState, [boolean, boolean, boolean]>((state) => [
    isDispatched(state, Farms.types.FETCH_USER_FARM),
    isPending(state, Farms.types.FETCH_USER_FARM),
    isFulfilled(state, Farms.types.FETCH_USER_FARM)
  ]);

  const [fdFetchDispatched, fdFetchPending, fdFetchFulfilled] = farmDetailsFetchStatus;

  /**
   * Get the farm details update status
   * @type {[boolean, boolean, boolean]}
   */
  const farmDetailsUpdateStatus = useSelector<SubscriptionsAppState, [boolean, boolean, boolean]>((state) => [
    isDispatched(state, Farms.types.UPDATE_USER_FARM),
    isPending(state, Farms.types.UPDATE_USER_FARM),
    isFulfilled(state, Farms.types.UPDATE_USER_FARM)
  ]);

  const [fdUpdateDispatched, fdUpdatePending, fdUpdateFulfilled] = farmDetailsUpdateStatus;

  /**
   * Get the account details fetch status
   * @type {[boolean, boolean, boolean]}
   */
  const accountDetailsFetchStatus = useSelector<SubscriptionsAppState, [boolean, boolean, boolean]>(
    (state) => [
      isDispatched(state, User.types.FETCH_ACCOUNT_DETAILS),
      isPending(state, User.types.FETCH_ACCOUNT_DETAILS),
      isFulfilled(state, User.types.FETCH_ACCOUNT_DETAILS)
    ]
  );

  const [adFetchDispatched, adFetchPending, adFetchFulfilled] = accountDetailsFetchStatus;

  /**
   * Get the account details update status
   * @type {[boolean, boolean, boolean]}
   */
  const accountDetailsUpdateStatus = useSelector<SubscriptionsAppState, [boolean, boolean, boolean]>(
    (state) => [
      isDispatched(state, User.types.UPDATE_ACCOUNT_DETAILS),
      isPending(state, User.types.UPDATE_ACCOUNT_DETAILS),
      isFulfilled(state, User.types.UPDATE_ACCOUNT_DETAILS)
    ]
  );

  const signupDetailsUpdateStatus = useSelector<SubscriptionsAppState, [boolean, boolean, boolean]>(
    (state) => [
      isDispatched(state, User.types.UPDATE_SIGNUP_DETAILS),
      isPending(state, User.types.UPDATE_SIGNUP_DETAILS),
      isFulfilled(state, User.types.UPDATE_SIGNUP_DETAILS)
    ]
  );

  const [adUpdateDispatched, adUpdatePending, adUpdateFulfilled] = accountDetailsUpdateStatus;

  /**
   * Get the signup state
   * @type {SignupState}
   */
  const state = useSelector<SubscriptionsAppState, SignupState>(Subscriptions.entities.getSignupValues);

  const { farmDetails, dhms, accountDetails, remoteSession } = state;

  let entry: ContextType["entry"];

  if (adFetchPending || fdFetchPending || _.every([farmDetails, dhms, remoteSession], _.isEmpty)) {
    entry = Routes.app.gettingStarted.selectRole.index;
  } else if (_.isEmpty(farmDetails)) {
    entry = Routes.app.gettingStarted.selectRole.owner.farmDetails;
  } else if (_.isEmpty(dhms)) {
    entry = Routes.app.gettingStarted.selectRole.owner.dhms;
  } else if (
    _.size(_.pickBy(accountDetails, _.negate(_.isEmpty))) <
    5 /* implicitly check whether all fields are filled in */
  ) {
    entry = Routes.app.gettingStarted.selectRole.owner.accountDetails;
  } else {
    entry = Routes.app.gettingStarted.selectRole.owner.appointment;
  }

  return (
    <DefaultContext.Provider
      value={{
        entry,
        state,
        api: {
          signupDetails: {
            update: (...params: Parameters<typeof User.actions.updateSignupDetails.start>) =>
              dispatch(User.actions.updateSignupDetails.start(...params))
          },
          farmDetails: {
            fetch: () => dispatch(Farms.actions.fetchUserFarm.start()),
            update: (...params) => dispatch(Signup.actions.push(...params))
          },
          accountDetails: {
            fetch: () => dispatch(User.actions.fetchAccountDetails.start()),
            update: (...params) => dispatch(Signup.actions.push(...params))
          }
        },
        meta: {
          signupDetails: {
            update: {
              dispatched: signupDetailsUpdateStatus[0],
              pending: signupDetailsUpdateStatus[1],
              fulfilled: signupDetailsUpdateStatus[2]
            }
          },
          farmDetails: {
            fetch: {
              dispatched: fdFetchDispatched,
              pending: fdFetchPending,
              fulfilled: fdFetchFulfilled
            },
            update: {
              dispatched: fdUpdateDispatched,
              pending: fdUpdatePending,
              fulfilled: fdUpdateFulfilled
            }
          },
          accountDetails: {
            fetch: {
              dispatched: adFetchDispatched,
              pending: adFetchPending,
              fulfilled: adFetchFulfilled
            },
            update: {
              dispatched: adUpdateDispatched,
              pending: adUpdatePending,
              fulfilled: adUpdateFulfilled
            }
          }
        }
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const GettingStartedContext = {
  Provider: _GettingStartedContextProvider,
  Consumer: DefaultContext.Consumer
};

export const useGettingStarted = (): ContextType => useContext(DefaultContext);
