import { FC, useEffect, useState } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useDispatch } from "react-redux";

import {
  CtraLayout,
  Typography,
  Navigation,
  Space,
  UserOutlined,
  Steps,
  Row,
  Col,
  useSpinner
} from "@ctra/components";

import { Subscriptions, Signup } from "@ctra/api";
import { useTranslation, Subscriptions as Content } from "@ctra/i18n";
import { GAContext } from "@ctra/analytics";

import { Routes } from "@routes";
import { GACategories as SignupGACategories } from "@signup";

import { AccountDetailsPage } from "../AccountDetailsPage";
import { FarmDetailsPage } from "../FarmDetailsPage";
import { DHMSDetailsPage } from "../DHMSDetailsPage";
import { AppointmentPage } from "../AppointmentPage";
import { useGettingStarted } from "../../providers";

import styles from "./GettingStartedPage.module.less";
import { CookieKeys, Cookies } from "@ctra/utils";
import { GACategories } from "../../analytics";

const { WidgetWrapper, ContentWrapper } = CtraLayout;
const { NaviCard } = Navigation;
const { Paragraph, Title } = Typography;
const SentryRoute = Sentry.withSentryRouting(Route);

/**
 * Getting started page wrapper with routing
 * @return {JSX.Element}
 * @constructor
 */
export const GettingStartedPage: FC = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [useEntry, setUseEntry] = useState(true);

  const {
    entry,
    state: { role: selectedRole },
    meta: {
      signupDetails: {
        update: { fulfilled: isSignupDetailsUpdateFulfilled }
      },
      farmDetails: {
        fetch: {
          dispatched: isFarmDetailsDispatched,
          pending: isFarmDetailsLoading,
          fulfilled: isFarmDetailsFulfilled
        },
        update: { pending: isUpdateFarmDetailsLoading }
      },
      accountDetails: {
        fetch: {
          dispatched: isAccountDetailsDispatched,
          pending: isAccountDetailsLoading,
          fulfilled: isAccountDetailsFulfilled
        },
        update: { pending: isUpdateAccountDetailsLoading }
      }
    },
    api: {
      signupDetails: { update: updateSignupDetails },
      farmDetails: { fetch: fetchFarmDetails },
      accountDetails: { fetch: fetchAccountDetails }
    }
  } = useGettingStarted();

  const {
    api: { toggle }
  } = useSpinner();

  const { owner, other } = Routes.app.gettingStarted.selectRole;

  const {
    navigation: { navicard },
    gettingStarted: {
      loader,
      title,
      subtitle,
      description,
      navigation: { accountDetails, appointment, farmDetails, dhms }
    }
  } = Content;

  /**
   * List of steps for the vertical navigation
   * @type {string[]}
   */
  const steps = [
    owner.farmDetails,
    owner.dhms,
    owner.accountDetails,
    owner.appointment,
    owner.dataIntegration
  ];

  useEffect(() => {
    if (isSignupDetailsUpdateFulfilled && selectedRole !== "Farmer") {
      const params = new URLSearchParams();
      params.set("role", selectedRole);

      if (Cookies.get(CookieKeys.referrer) && !["Farmer", "Explorer"].includes(selectedRole)) {
        /**
         * If you are anything but a Farmer or Explorer,
         * then you are redirected to the platform.
         * After logging in again, you should not come back here.
         *
         * @todo probably this is obsolete as the redirect will be done via the user-info
         */
        Cookies.remove(CookieKeys.referrer);
      }

      Subscriptions.history.push({
        pathname: Routes.external.enterprise,
        search: params.toString()
      });
    }
  }, [isSignupDetailsUpdateFulfilled, selectedRole]);

  /**
   * Fetch the farm details and account details on mount
   */
  useEffect(() => {
    if (!isFarmDetailsDispatched) {
      fetchFarmDetails();
    }

    if (!isAccountDetailsDispatched) {
      fetchAccountDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFarmDetailsDispatched, isAccountDetailsDispatched]);

  /**
   * Show the spinner if fetching the farm details or account details
   */
  useEffect(() => {
    toggle(
      "gettingStarted",
      isFarmDetailsLoading ||
        isAccountDetailsLoading ||
        isUpdateFarmDetailsLoading ||
        isUpdateAccountDetailsLoading,
      {
        tip: t<string>(loader, {
          status:
            isFarmDetailsLoading || isAccountDetailsLoading
              ? "fetching"
              : isUpdateFarmDetailsLoading || isUpdateAccountDetailsLoading
              ? "updating"
              : null
        })
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFarmDetailsLoading,
    isAccountDetailsLoading,
    isUpdateFarmDetailsLoading,
    isUpdateAccountDetailsLoading
  ]);

  /**
   * Allow the users to navigate freely after the data is fetched
   */
  useEffect(() => {
    if (isFarmDetailsFulfilled && isAccountDetailsFulfilled) {
      setUseEntry(false);
    }
  }, [isFarmDetailsFulfilled, isAccountDetailsFulfilled]);

  return pathname !== entry && useEntry && entry ? (
    <Redirect to={entry} />
  ) : (
    <Switch>
      <SentryRoute path={owner.index}>
        <WidgetWrapper omitHeader className={styles.PageWrapper}>
          <Row gutter={[16, 16]}>
            <Col span={8} md={6} lg={4}>
              <Steps
                direction="vertical"
                current={steps.indexOf(pathname)}
                items={[
                  { title: t<string>(farmDetails) },
                  { title: t<string>(dhms) },
                  { title: t<string>(accountDetails) },
                  { title: t<string>(appointment) }
                ]}
              />
            </Col>
            <Col span={16} md={18} lg={20}>
              <Switch>
                <SentryRoute path={owner.farmDetails}>
                  <GAContext.Provider value={{ category: SignupGACategories.farmDetails }}>
                    <FarmDetailsPage />
                  </GAContext.Provider>
                </SentryRoute>
                <SentryRoute path={owner.dhms}>
                  <GAContext.Provider value={{ category: SignupGACategories.dhms }}>
                    <DHMSDetailsPage />
                  </GAContext.Provider>
                </SentryRoute>
                <SentryRoute path={owner.accountDetails}>
                  <GAContext.Provider value={{ category: SignupGACategories.accountDetails }}>
                    <AccountDetailsPage />
                  </GAContext.Provider>
                </SentryRoute>
                <SentryRoute path={owner.appointment}>
                  <GAContext.Provider value={{ category: SignupGACategories.appointment }}>
                    <AppointmentPage widget="embed" />
                  </GAContext.Provider>
                </SentryRoute>
                <SentryRoute>
                  <Redirect to={owner.farmDetails} />
                </SentryRoute>
              </Switch>
            </Col>
          </Row>
        </WidgetWrapper>
      </SentryRoute>
      <SentryRoute>
        <GAContext.Provider value={{ category: SignupGACategories.selectRole }}>
          <WidgetWrapper omitHeader className={styles.RolePickWrapper}>
            <ContentWrapper>
              <Title level={2} weight={400}>
                {t<string>(title)}
              </Title>
              <Title level={4} weight={400} style={{ marginTop: 0 }}>
                {t<string>(subtitle)}
              </Title>
              <Paragraph>{t<string>(description)}</Paragraph>
              <Switch>
                <SentryRoute path={other}>
                  <Space direction="vertical" size="middle">
                    <NaviCard
                      data-gtm-category={GACategories.gettingStarted}
                      data-gtm-action="Select advisor role"
                      icon={<UserOutlined />}
                      title={t<string>(navicard.advisor.title)}
                      description={t<string>(navicard.advisor.description)}
                      onClick={() => {
                        if (useEntry) {
                          setUseEntry(false);
                        }

                        updateSignupDetails({
                          role: "Advisor",
                          isSignupCompleted: true
                        });

                        dispatch(
                          Signup.actions.commit({
                            role: "Advisor"
                          })
                        );
                      }}
                    />
                    <NaviCard
                      data-gtm-category={GACategories.gettingStarted}
                      data-gtm-action="Select employee role"
                      icon={<UserOutlined />}
                      title={t<string>(navicard.employee.title)}
                      description={t<string>(navicard.employee.description)}
                      onClick={() => {
                        if (useEntry) {
                          setUseEntry(false);
                        }

                        updateSignupDetails({
                          role: "Other",
                          isSignupCompleted: true
                        });

                        dispatch(
                          Signup.actions.commit({
                            role: "FarmEmployee"
                          })
                        );
                      }}
                    />
                  </Space>
                </SentryRoute>
                <SentryRoute>
                  <Space direction="vertical" size="middle">
                    <NaviCard
                      data-gtm-category={GACategories.gettingStarted}
                      data-gtm-action="Select owner role"
                      icon={<UserOutlined />}
                      title={t<string>(navicard.owner.title)}
                      description={t<string>(navicard.owner.description)}
                      onClick={() => {
                        if (useEntry) {
                          setUseEntry(false);
                        }

                        updateSignupDetails({
                          role: "Farmer",
                          isSignupCompleted: false
                        });

                        dispatch(Signup.actions.commit({ role: "Farmer" }));
                        Subscriptions.history.push(owner.index);
                      }}
                    />
                    <NaviCard
                      data-gtm-category={GACategories.gettingStarted}
                      data-gtm-action="Select other role"
                      icon={<UserOutlined />}
                      title={t<string>(navicard.other.title)}
                      description={t<string>(navicard.other.description)}
                      onClick={() => {
                        if (useEntry) {
                          setUseEntry(false);
                        }

                        Subscriptions.history.push(other);
                      }}
                    />
                    <NaviCard
                      data-gtm-category={GACategories.gettingStarted}
                      data-gtm-action="Select explorer role"
                      title={t<string>(navicard.explorer.title)}
                      description={t<string>(navicard.explorer.description)}
                      onClick={() => {
                        if (useEntry) {
                          setUseEntry(false);
                        }

                        updateSignupDetails({
                          role: "Explorer",
                          isSignupCompleted: false
                        });

                        dispatch(
                          Signup.actions.commit({
                            role: "Explorer"
                          })
                        );
                      }}
                    />
                  </Space>
                </SentryRoute>
              </Switch>
            </ContentWrapper>
          </WidgetWrapper>
        </GAContext.Provider>
      </SentryRoute>
    </Switch>
  );
};
