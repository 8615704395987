import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Request, Session, SubscriptionsAppState, Subscriptions, TokenEntity } from "@ctra/api";

/**
 * Authenticate with token
 * @return {null}
 * @constructor
 */
export const RegisterToken: FC = () => {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector<SubscriptionsAppState, boolean>(Subscriptions.entities.isLoggedIn);
  const hasLoggedOut = useSelector<SubscriptionsAppState, boolean>(Subscriptions.entities.hasLoggedOut);
  const token = useSelector<SubscriptionsAppState, TokenEntity>(Subscriptions.entities.getToken);

  useEffect(() => {
    if (isLoggedIn) {
      const { accessToken } = token;

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      Request.registerToken(accessToken!);
    } else if (!hasLoggedOut) {
      dispatch(Session.actions.logout());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, hasLoggedOut]);

  return null;
};
