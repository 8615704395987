import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import * as _ from "lodash";

import { Auth, SubscriptionsAppState } from "@ctra/api";
import { Alert, Auth as AuthUI, Grid, Typography } from "@ctra/components";
import { Subscriptions as Content, parseLocoTemplate, useTranslation } from "@ctra/i18n";
import { isFulfilled, isPending } from "@ctra/utils";

import { LanguageSelector } from "@base";
import { Routes } from "@routes";
import { GACategories } from "../../analytics";

const { Text, Paragraph } = Typography;
const { AuthPage, WidgetWrapper } = AuthUI;

export enum ActivationType {
  resend = "resend",
  initial = "initial"
}

/**
 * Activation page
 * @constructor
 */
const SignupSuccessPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { md } = Grid.useBreakpoint();
  const searchParams = new URLSearchParams(search);
  const email = searchParams.get("email");

  const {
    pageTitle,
    pageContent: { intro, mailInfo },
    alerts: { emailSent },
    actions: { resend }
  } = Content.activate;

  /**
   * Tell whether the activation request is pending
   * @type {boolean}
   */
  const isLoading = useSelector<SubscriptionsAppState, boolean>((state) =>
    isPending(state, Auth.types.REQUEST_ACTIVATION)
  );

  /**
   * Tell whether the activation email has been sent
   * @type {boolean}
   */
  const isActivationRequested = useSelector<SubscriptionsAppState, boolean>((state) =>
    isFulfilled(state, Auth.types.REQUEST_ACTIVATION)
  );

  /**
   * Login text and link
   */
  const [loginText, loginLink] = _.defaultTo(parseLocoTemplate(t<string>(Content.signup.form.footer)), []);

  /**
   * Make parts from the email copy
   */
  const [emailDescriptionLead, emailAddress] = _.defaultTo(
    parseLocoTemplate(
      t<string>(mailInfo, {
        email
      })
    ),
    []
  );

  /**
   * Make parts from the resend instructions copy
   */
  const [resendInstructionLead, resendInstructionsLink, resendInstructionsTail] = _.defaultTo(
    parseLocoTemplate(t<string>(resend)),
    []
  );

  return (
    <AuthPage header={<LanguageSelector />}>
      <WidgetWrapper
        introTitle={t<string>(pageTitle, { variant: ActivationType.initial })}
        footer={
          <>
            <Text>{loginText}</Text>
            {md ? " " : <br />}
            <Text strong>
              <Link to={Routes.auth.login}>{loginLink}</Link>
            </Text>
          </>
        }
      >
        <Paragraph>{t(intro)}</Paragraph>
        <Paragraph style={{ marginBottom: 0 }}>
          <Text>{emailDescriptionLead}</Text>
          <Text strong>{emailAddress}</Text>
          {". "}
          <Text>{resendInstructionLead}</Text>
          <Typography.Link
            strong
            data-gtm-category={GACategories.signup}
            data-gtm-action="Resend activation email"
            onClick={() => dispatch(Auth.actions.requestActivation.start(email))}
            disabled={isLoading || isActivationRequested}
          >
            {resendInstructionsLink}
          </Typography.Link>
          <Text>{resendInstructionsTail}</Text>
        </Paragraph>
        {isActivationRequested && (
          <Alert style={{ marginTop: "16px" }} closable showIcon type="success" message={t(emailSent)} />
        )}
      </WidgetWrapper>
    </AuthPage>
  );
};

export default SignupSuccessPage;
