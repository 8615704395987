import { version } from "../../package.json";
import { Nullable } from "@ctra/utils";

/**
 * Get the version from the package.json
 * @return {number}
 */
export const getVersion = (): number => {
  return parseFloat(version.replace(/\./g, ""));
};

/**
 * Get the storage key prefix
 * @param {boolean} persist
 * @return {`persist:ctra.subscriptions.v${number}` | `ctra.subscriptions.v${number}`}
 */
export const getStorageKey = ({ persist }: { persist: boolean } = { persist: false }): string => {
  return `${persist ? "persist:" : ""}ctra.subscriptions.v${getVersion()}`;
};

/**
 * Check if the key matches the current version
 * @param {Nullable<string>} key
 * @return {key is string}
 */
const _matches = (key: Nullable<string>): key is string => {
  const matchesSubscriptions = key && key.match(/^ctra\.subscriptions/);
  const matchesVersion = key && key.match(new RegExp(getVersion().toString(), "gi"));

  return !!matchesSubscriptions && !matchesVersion;
};

/**
 * Purge the storage
 */
export const purgeStaleData: () => void = () => {
  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i);

    if (_matches(key)) {
      sessionStorage.removeItem(key);
    }
  }

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);

    if (_matches(key)) {
      localStorage.removeItem(key);
    }
  }
};
